<template>
  <div>
    <ul>
      <li>
        Menu principal de gauche <v-icon>mdi-chevron-right</v-icon>
        <span class="font-weight-bold">Mon organisation</span>
      </li>
      <li>
        Bouton
        <PlusButton />
        dans la zone des professionnels
      </li>
      <li>
        Remplissez :
        <ul>
          <li>Le <span class="font-weight-bold">prénom</span></li>
          <li>Le <span class="font-weight-bold">nom</span></li>
          <li>
            L'<span class="font-weight-bold">adresse e-mail</span> (le
            professionnel devra entrer cette adresse e-mail pour pouvoir se
            connecter à monplanning)
          </li>
        </ul>
      </li>
      <li>
        Cliquez sur
        <SaveButton />
      </li>
    </ul>
    <br />
    <div>
      Pour bénéficier immédiatement de ce nouvel accès, monplanning calculera
      un ratio entre le prix que vous avez déjà payé et le nouveau prix. Dans ce
      cas, la durée de votre offre sera réduite.
    </div>
  </div>
</template>

<script>
import SaveButton from "../Components/SaveButton";
import PlusButton from "../Components/PlusButton";

export default {
  components: {
    SaveButton,
    PlusButton,
  },
};
</script>