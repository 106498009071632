<template>
  <ul>
    <li>
      Menu principal de gauche <v-icon>mdi-chevron-right</v-icon>
      <span class="font-weight-bold">Cours</span>
    </li>
    <li>
      Bouton
      <PlusButton />
      en haut à gauche
    </li>
    <li>
      Remplissez :
      <ul>
        <li>Le <span class="font-weight-bold">nom du cours</span> (exemple: "Lundi soir")</li>
        <li>La <span class="font-weight-bold">couleur</span> (cette couleur sera affichée dans votre planning)
        <li>Le <span class="font-weight-bold">nom de l'activité</span> (exemple: "Hatha Yoga")</li>
        <li>Les heures de <span class="font-weight-bold">début et fin</span> </li>
        <li>Si c'est un <span class="font-weight-bold">cours hebdomaire</span>, cochez la case et sélectionnez le <span class="font-weight-bold">jour de la semaine</span></li>
        <li>Le site (géographique) où le cours sera donné (optionnel)</li>
        <li>
          Le nombre d'unités maximum (combien de participants au maximum peuvent
          assister au cours) - laissez vide s'il n'y aucune limite
        </li>
        <li>Le professionnel qui donne le cours</li>
        <li>La description (optionnelle)</li>
      </ul>
    </li>
    <li>
      Cliquez sur
      <SaveButton />
    </li>
    <li>
      Vous pouvez "générer le planning" (cours récurrent hebdomaire) ou "choisir les dates" (cours ponctuel) pour remplir votre planning
    </li>
  </ul>
</template>

<script>
import SaveButton from "../Components/SaveButton";
import PlusButton from "../Components/PlusButton";

export default {
  components: {
    SaveButton,
    PlusButton,
  },
};
</script>