import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VExpansionPanel } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelContent } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelHeader } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanels } from 'vuetify/lib/components/VExpansionPanel';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VContainer,{staticClass:"mb-6 gradient"},[_c(VRow,{attrs:{"justify":"center","align":"start"}},[_c(VCol,{staticClass:"shrink",attrs:{"md":"2"}},[_c(VImg,{staticClass:"rounded-lg",attrs:{"src":require("@/assets/help-center.png"),"max-height":"280","max-width":"350"}})],1),_c(VCol,{staticClass:"ml-6 text-h3",attrs:{"md":"5"}},[_c('div',{staticClass:"text-h4 font-weight-medium"},[_vm._v("Bonjour,")]),_c('h1',{staticClass:"text-h4 primary--text font-weight-medium"},[_vm._v(" comment pouvons-nous vous aider? ")]),_c(VTextField,{staticClass:"mt-8",attrs:{"outlined":"","placeholder":"Recherche","clearable":"","prepend-inner-icon":"mdi-magnify"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c(VCol,{staticClass:"ml-5 shrink"})],1),_c(VRow,{attrs:{"justify":"center"}},[_c(VCol,{staticClass:"text-body-1",attrs:{"md":"7"}},[_vm._v(" monplanning est conçu pour être rapide à prendre en main et facile à utiliser au quotidien, même si vous n'êtes pas un(e) as de l’informatique. Et si vous ne deviez pas comprendre quelque chose, nous mettons à votre disposition toute une panoplie de supports d'aide pour assister vos premiers pas : centre d'aide, service de support par e-mail, assistance via notre chat en ligne, ... Votre question ne restera pas longtemps sans réponse ! ")])],1)],1),_c(VContainer,[_c(VRow,{attrs:{"justify":"center"}},[_c(VCol,{attrs:{"sm":"8"}},[_c(VExpansionPanels,{attrs:{"multiple":""},model:{value:(_vm.panel),callback:function ($$v) {_vm.panel=$$v},expression:"panel"}},_vm._l((_vm.filteredItems),function(item,i){return _c(VExpansionPanel,{key:i,staticClass:"mb-4"},[_c(VExpansionPanelHeader,[_c('span',[_c(VIcon,{staticClass:"mr-4",attrs:{"color":"primary"}},[_vm._v("mdi-help-circle")]),_c('span',{domProps:{"innerHTML":_vm._s(
                    _vm.search
                      ? ("<h2>" + (item.title.replace(
                          new RegExp(_vm.search, 'ig'),
                          '<span class=primary>' + _vm.search + '</span>'
                        )) + "</h2>")
                      : ("<h2 class='text-h6 font-weight-regular'>" + (item.title) + "</h2>")
                  )}})],1)]),_c(VExpansionPanelContent,[_c(item.description,{tag:"component"})],1)],1)}),1)],1)],1),_c(VRow,{staticClass:"mt-16",attrs:{"justify":"center"}},[_c(VCol,{attrs:{"sm":"8"}},[_c('div',{staticClass:"text-h5"},[_vm._v(" Vous n'avez pas trouvé réponse à vos questions ? ")]),_c('div',{staticClass:"text-h5 font-weight-medium primary--text mt-2"},[_c(VBtn,{attrs:{"to":_vm.$helpers.router.getPublicLink('contact'),"color":"primary"}},[_vm._v("Nous y répondons !")])],1)])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }