<template>
  <ul>
    <li>
      Menu principal de gauche <v-icon>mdi-chevron-right</v-icon>
      <span class="font-weight-bold">Mon planning</span>
    </li>
    <li>Cliquez sur le <span class="font-weight-bold">planning</span> du cours</li>
    <li>
      Cliquez sur
      <DeleteButton />
    </li>
    <li>Ce planning <span class="font-weight-bold">disparaitra</span> de votre planning et tous les <span class="font-weight-bold">participants récupèreront</span> l'unité sur leur abonnement</li>
  </ul>
</template>

<script>
import DeleteButton from "../Components/DeleteButton";

export default {
  components: {
    DeleteButton,
  },
};
</script>