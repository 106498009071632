<template>
  <ul>
    <li>
      Menu principal de gauche <v-icon>mdi-chevron-right</v-icon>
      <span class="font-weight-bold">Mon planning</span>
    </li>
    <li>Cliquez sur le <span class="font-weight-bold">planning</span> du cours</li>
    <li>
      Cliquez sur la boîte des <span class="font-weight-bold">Participants</span> et faites une
      recherche sur le <span class="font-weight-bold">nom du client</span> (prénom, nom de famille ou
      nom de la société) ou sur le nom d'un groupe
    </li>
    <li>Cliquez sur le bouton <Plus1Button /></li>
    <li>La liste des <span class="font-weight-bold">participants</span> s'agrandit</li>
    <li>
      Cliquez sur
      <SaveButton />
    </li>
  </ul>
</template>

<script>
import SaveButton from "../Components/SaveButton";
import Plus1Button from "../Components/Plus1Button";

export default {
  components: {
    SaveButton,
    Plus1Button,
  },
};
</script>