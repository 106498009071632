<template>
  <div>
    <v-container class="mb-6 gradient">
      <v-row justify="center" align="start">
        <v-col class="shrink" md="2">
          <v-img
            src="@/assets/help-center.png"
            class="rounded-lg"
            max-height="280"
            max-width="350"
          ></v-img>
        </v-col>
        <v-col md="5" class="ml-6 text-h3">
          <div class="text-h4 font-weight-medium">Bonjour,</div>
          <h1 class="text-h4 primary--text font-weight-medium">
            comment pouvons-nous vous aider?
          </h1>
          <v-text-field
            outlined
            placeholder="Recherche"
            clearable
            v-model="search"
            prepend-inner-icon="mdi-magnify"
            class="mt-8"
          />
        </v-col>
        <v-col class="ml-5 shrink" />
      </v-row>
      <v-row justify="center">
        <v-col md="7" class="text-body-1">
            monplanning est conçu pour être rapide à prendre en main et facile
            à utiliser au quotidien, même si vous n'êtes pas un(e) as de
            l’informatique. Et si vous ne deviez pas comprendre quelque chose,
            nous mettons à votre disposition toute une panoplie de supports
            d'aide pour assister vos premiers pas : centre d'aide, service de
            support par e-mail, assistance via notre chat en ligne, ... Votre
            question ne restera pas longtemps sans réponse !
        </v-col>
      </v-row>
    </v-container>

    <v-container>
      <v-row justify="center">
        <v-col sm="8">
          <v-expansion-panels multiple v-model="panel">
            <v-expansion-panel
              v-for="(item, i) in filteredItems"
              :key="i"
              class="mb-4"
            >
              <v-expansion-panel-header
                ><span>
                  <v-icon class="mr-4" color="primary">mdi-help-circle</v-icon>
                  <span
                    class=""
                    v-html="
                      search
                        ? `<h2>${item.title.replace(
                            new RegExp(search, 'ig'),
                            '<span class=primary>' + search + '</span>'
                          )}</h2>`
                        : `<h2 class=\'text-h6 font-weight-regular\'>${item.title}</h2>`
                    "
                  ></span>
                </span>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <component :is="item.description" />
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-col>
      </v-row>
      <v-row class="mt-16" justify="center">
        <v-col sm="8">
          <div class="text-h5">
            Vous n'avez pas trouvé réponse à vos questions ?
          </div>
          <div class="text-h5 font-weight-medium primary--text mt-2">
            <v-btn
              :to="$helpers.router.getPublicLink('contact')"
              color="primary"
              >Nous y répondons !</v-btn
            >
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import AddLesson from "@/components/Public/HelpCenter/Items/AddLesson";
import GeneratePlanningLesson from "@/components/Public/HelpCenter/Items/GeneratePlanningLesson";
import PickPlanningLesson from "@/components/Public/HelpCenter/Items/PickPlanningLesson";
import AddClient from "@/components/Public/HelpCenter/Items/AddClient";
import AddMembershipType from "@/components/Public/HelpCenter/Items/AddMembershipType";
import AddClientMembership from "@/components/Public/HelpCenter/Items/AddClientMembership";
import AddPlanningMembership from "@/components/Public/HelpCenter/Items/AddPlanningMembership";
import DeletePlanning from "@/components/Public/HelpCenter/Items/DeletePlanning";
import CancelPlanning from "@/components/Public/HelpCenter/Items/CancelPlanning";
import UserInformation from "@/components/Public/HelpCenter/Items/UserInformation";
import AddUser from "@/components/Public/HelpCenter/Items/AddUser";
import BillPlanInformation from "@/components/Public/HelpCenter/Items/BillPlanInformation";
import ChangeBillPlan from "@/components/Public/HelpCenter/Items/ChangeBillPlan";
import OrganizationInformation from "@/components/Public/HelpCenter/Items/OrganizationInformation";
import ViewCalendar from "@/components/Public/HelpCenter/Items/ViewCalendar";
import SiteInformation from "@/components/Public/HelpCenter/Items/SiteInformation";
import ChangeOrganizationUrl from "@/components/Public/HelpCenter/Items/ChangeOrganizationUrl";
import LimitLessonMembershipType from "@/components/Public/HelpCenter/Items/LimitLessonMembershipType";
import ChangeMollieAPIKey from "@/components/Public/HelpCenter/Items/ChangeMollieAPIKey";

export default {
  name: "HelpCenter",
  components: {
    AddLesson,
    GeneratePlanningLesson,
    PickPlanningLesson,
    AddClient,
    AddMembershipType,
    AddClientMembership,
    AddPlanningMembership,
    DeletePlanning,
    CancelPlanning,
    UserInformation,
    AddUser,
    BillPlanInformation,
    ChangeBillPlan,
    OrganizationInformation,
    ViewCalendar,
    SiteInformation,
    ChangeOrganizationUrl,
    LimitLessonMembershipType,
    ChangeMollieAPIKey,
  },
  watch: {
    search(val) {
      this.customFilter(val);
    },
  },
  data() {
    return {
      items: [],
      filteredItems: [],
      panel: [],
      search: null,
    };
  },
  computed: {},
  methods: {
    // expand(i) {
    //   console.dir(this.panel);
    //   if (this.panel.includes(i)) this.panel.splice(this.panel.indexOf(i), 1);
    //   else this.panel.push(i);
    //   console.dir(this.panel);
    // },
    async customFilter(search) {
      if (!search) {
        this.filteredItems = this.items;
        return;
      }

      search = (search ?? "").toLowerCase();

      try {
        // this.filteredItems =
        //   this.items.filter((i) => i.title.toLowerCase().includes(search)) ??
        //   [];
        this.filteredItems =
          this.items.filter((i) => i.title.match(new RegExp(search, "ig"))) ??
          [];
      } catch (error) {
        this.$helpers.snackbar.handleError(error);
      }
    },
  },
  mounted() {
    const languageItems = [
      "AddClient",
      "AddLesson",
      "GeneratePlanningLesson",
      "PickPlanningLesson",
      "AddMembershipType",
      "AddClientMembership",
      "AddPlanningMembership",
      "DeletePlanning",
      "CancelPlanning",
      "UserInformation",
      "AddUser",
      "BillPlanInformation",
      "ChangeBillPlan",
      "OrganizationInformation",
      "ViewCalendar",
      "SiteInformation",
      "ChangeOrganizationUrl",
      "LimitLessonMembershipType",
      "ChangeMollieAPIKey",
    ];

    languageItems.forEach((element) => {
      this.items.push({
        title: this.$i18n.t("helpcenter.items." + element),
        description: element,
      });
    });

    this.filteredItems = this.items;
  },
};
</script>