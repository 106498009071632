<template>
  <ul>
    <li>
      Menu principal de gauche <v-icon>mdi-chevron-right</v-icon>
      <span class="font-weight-bold">Types d'adhésion</span>
    </li>
    <li>
      Vous avez le choix entre 3 menus :
      <ul>
        <li>Séances uniques</li>
        <li>Cartes abonnement</li>
        <li>Abonnement</li>
      </ul>
    </li>
    <li>
      Bouton
      <PlusButton />
      en haut à gauche
    </li>
    <li>
      Remplissez :

      <ul>
        <li>
          Le <span class="font-weight-bold">nom</span> de l'abonnement (exemple: "Carte 10 séances")
        </li>
        <li>
          Le nombre <span class="font-weight-bold">d'unités</span> (exemple dans notre cas: "10" pour
          10 séances). Laissez vide s'il n'y a aucune limite.
        </li>
        <li>Le <span class="font-weight-bold">prix</span> (vide si gratuit)</li>
        <li>Le <span class="font-weight-bold">taux de TVA</span> applicable</li>
        <li>Le <span class="font-weight-bold">validité</span> de l'abonnement (exprimé en jour, semaine, mois ou année). Laissez vide s'il n'y a aucune limite</li>
      </ul>
    </li>
    <li>
      Cliquez sur
      <SaveButton />
    </li>
  </ul>
</template>

<script>
import SaveButton from "../Components/SaveButton";
import PlusButton from "../Components/PlusButton";

export default {
  components: {
    SaveButton,
    PlusButton,
  },
};
</script>