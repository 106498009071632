<template>
  <div>
    <p>
              Mollie est un fournisseur de paiement par internet. Il vous
              permettra de recevoir les paiements de vos clients directement sur
              votre compte Mollie. Vous pouvez retrouver plus d'informations sur
              <a href="https://www.mollie.com" target="_blank"
                >www.mollie.com</a
              >
            </p>
            <p>
              Une fois que votre compte est configuré, les clients pourront
              acheter une carte abonnement sur votre web publique.
            </p>
    <ul>
      <li>
        Menu principal de gauche <v-icon>mdi-chevron-right</v-icon>
        <span class="font-weight-bold">Mon organisation</span>
      </li>
      <li>
        Bouton
        <EditButton />
        à côté du nom de votre organisation/activité
      </li>
      <li>
        Remplissez :
        <ul>
          <li>La <span class="font-weight-bold">clé Mollie</span></li>
        </ul>
      </li>
      <li>
        Cliquez sur
        <SaveButton />
      </li>
    </ul>
  </div>
</template>

<script>
import SaveButton from "../Components/SaveButton";
import EditButton from "../Components/EditButton";

export default {
  components: {
    SaveButton,
    EditButton,
  },
};
</script>