<template>
  <div>
    <ul>
      <li>
        Menu principal de gauche <v-icon>mdi-chevron-right</v-icon>
        <span class="font-weight-bold">Mon organisation</span>
      </li>
      <li>
        Bouton
        <EditButton />
        à côté du nom de votre organisation/activité
      </li>
      <li>
        Remplissez :
        <ul>
          <li>
            L'<span class="font-weight-bold">URL</span> correspondant au nom de
            votre page publique
          </li>
          <li>
            Utilisez un nom court et facile à retenir. Les espaces et les
            caractères spéciaux ne sont pas autorisés.
          </li>
        </ul>
      </li>
      <li>
        Cliquez sur
        <SaveButton />
      </li>
      <li>
        Vérifiez le résultat dans le menu principal de gauche
        <v-icon>mdi-chevron-right</v-icon> "Voir ma page publique"
      </li>
    </ul>
  </div>
</template>

<script>
import SaveButton from "../Components/SaveButton";
import EditButton from "../Components/EditButton";

export default {
  components: {
    SaveButton,
    EditButton,
  },
};
</script>