<template>
  <ul>
    <li>
      Menu principal de gauche <v-icon>mdi-chevron-right</v-icon>
      <span class="font-weight-bold">Abonnements</span>
    </li>
    <li>
      Bouton
      <PlusButton />
      en haut à gauche
    </li>
    <li>
      Remplissez :
      <ul>
        <li>Le <span class="font-weight-bold">client</span></li>
        <li>Le <span class="font-weight-bold">type d'abonnement</span> précédemment créé</li>
        <li>
          Les <span class="font-weight-bold">dates de validité</span> sont automatiquement
          pré-remplies en fonction de l'abonnement. Il est possible de modifier
          manuellement les dates par la suite.
        </li>
        <li>
          Le <span class="font-weight-bold">prix</span> et le <span class="font-weight-bold">taux de TVA</span> sont
          pré-remplis. Il est également possible de les modifier manuellement.
        </li>
        <li>
          Le <span class="font-weight-bold">paiement</span>
          <ul>
            <li>Le <span class="font-weight-bold">statut</span></li>
            <li>La <span class="font-weight-bold">date</span></li>
            <li>Le <span class="font-weight-bold">type de paiement</span></li>
          </ul>
        </li>
      </ul>
    </li>
    <li>
      Cliquez sur
      <SaveButton />
    </li>
  </ul>
</template>

<script>
import SaveButton from "../Components/SaveButton";
import PlusButton from "../Components/PlusButton";

export default {
  components: {
    SaveButton,
    PlusButton,
  },
};
</script>