<template>
  <ul>
    <li>
      Menu principal de gauche <v-icon>mdi-chevron-right</v-icon>
      <span class="font-weight-bold">Clients</span>
    </li>
    <li>
      Bouton
      <PlusButton />
      en haut à gauche
    </li>
    <li>
      Remplissez les champs disponibles
    </li>
    <li>
      Cliquez sur
      <SaveButton />
    </li>
  </ul>
</template>

<script>
import SaveButton from "../Components/SaveButton";
import PlusButton from "../Components/PlusButton";

export default {
  components: {
    SaveButton,
    PlusButton,
  },
};
</script>