<template>
  <ul>
    <li>
      Menu principal de gauche <v-icon>mdi-chevron-right</v-icon>
      <span class="font-weight-bold">Cours</span>
    </li>
    <li>
      Bouton
      <EditButton />
      à côté du nom du cours
    </li>
    <li>
      Dans le bas du formulaire :

      <ul>
        <li>
          Cochez <span class="font-weight-bold">"Limiter  à certains types d'abonnement"</span>
        </li>
        <li>
          Sélectionnez les différents éléments dans la liste. Si aucun élément n'est sélectionné, il n'y aura aucune limitation.
        </li>
      </ul>
    </li>
    <li>
      Cliquez sur
      <SaveButton />
    </li>
  </ul>
</template>

<script>
import SaveButton from "../Components/SaveButton";
import EditButton from "../Components/EditButton";

export default {
  components: {
    SaveButton,
    EditButton,
  },
};
</script>