<template>
  <span
    >Un <span class="font-weight-bold">site</span> est un endroit géographique où vous dispensez vos cours. Cette option est optionnelle.<br />
    Pour ajouter un nouveau site :
    <ul>
      <li>Menu principal de gauche <v-icon>mdi-chevron-right</v-icon>
        <span class="font-weight-bold">Sites</span>
      </li>
          <li>
      Bouton
      <PlusButton />
      en haut à gauche
    </li>
    </ul>
  </span>
</template>

<script>
import PlusButton from "../Components/PlusButton";

export default {
  components: {
    PlusButton,
  },
};
</script>